const TYPE_MESSAGE = 0;
const TYPE_MODULE = 1;

require('./common');

let $chatMessageForm = $('#chatMessageForm');

window.appendPart = function (tplData) {
    $.each(tplData, function(k, v) {

        if(k === 'chatMessages') {

            toggleTyping('show');

            let words = v.split(' '),
                speed = 5000;

            setTimeout(function() {
                pushMessage({'content' : v}, TYPE_MESSAGE, 'from', true);
            }, speed);

            resetChatForm();
        }
        else {
            $(`#${k}`).html(v);
        }

    });

    setTimeout(function() {
        updateScroll();
    }, 200);

};

window.runScene = function(id, step) {

    if(id === 0) {

        $.ajax({
            type: 'post',
            url: Routing.generate('app_funnel_end', { uuid: window.uuid, token: window.token } ),
            dataType: 'json',
            data: {}
        }).done(function(res) {

            $('#mainModal')
                .find('.modal-content')
                    .html(res.html)
                    .end()
                .modal('show');
        });


    }
    else {

        $.ajax({
            type: 'post',
            url: Routing.generate('app_scene', { uuid: window.uuid, token: window.token, sid: id, offset: step } ),
            dataType: 'json',
            data: {}
        }).done(function(res) {

            let instructions = res.scene,
                breakLoop = false;

            for (let i = 0, p = Promise.resolve(); i < instructions.length && ! breakLoop; i++) {

                let timer = (i === 0) ? 400 : Math.floor((Math.random() * (1800 - 600) + 600));

                p = p.then(_ => new Promise((resolve, reject)  =>
                    setTimeout(function () {

                        let instruction = instructions[i];

                        switch (instruction.type) {

                            case TYPE_MESSAGE:

                                toggleTyping('show');

                                let words = instruction.content.split(' '),
                                    speed = getTypingSpeed(words.length);

                                setTimeout(function() {

                                    pushMessage({'content' : instruction.content}, TYPE_MESSAGE, 'from');

                                    $.post(Routing.generate('app_landing_chat_ping', {
                                        uuid: window.uuid,
                                        token: window.token,
                                        instructionId: instruction.id,
                                    }));

                                    resolve();


                                }, speed);

                                break;

                            case TYPE_MODULE:

                                setTimeout(function() {

                                    switch(instruction.module_type) {

                                        case 0:

                                            let $el = $(`<div class="module" data-consumed="false" />`);
                                            $el.html(instruction.content);

                                            $chatMessageForm.find('#live').hide();
                                            $chatMessageForm.append($el);

                                            break;

                                        case 1:

                                            pushMessage({'content': instruction.content}, TYPE_MODULE, 'from');
                                            break;

                                        case 2:

                                            $('#mainModal').find('.modal-content').html(instruction.content);

                                            $('#mainModal').modal({
                                                backdrop: 'static',
                                                show: true
                                            });
                                            break;
                                    }

                                    return;

                                }, 200);

                                break;

                        }

                    }, timer)

                ));
            }

        });
    }

};


let form = $('form#live'),
    textarea = form.find('textarea[name="message"]'),
    init = new Promise(function(resolve, reject) {
        setTimeout(function() {
            loadPart(Routing.generate('app_member_chat_initialize', {}), {});
            resolve();
        }, 500);
    });

form
    .on('submit', function(e) {

        e.preventDefault();

        if(textarea.val() != '') {

            $.ajax({
                method: 'POST',
                url: form.attr('action'),
                type: 'json',
                data: form.serializeArray(),
            }).done(function (res) {

                textarea.val('');
                if (res.success === true) {
                    pushMessage({content: res.message}, 0, 'to', false);
                }

            });

        }

    })
;

let checkMessages = setInterval(function() {

    $.ajax({
        method: 'POST',
        url: Routing.generate('app_member_check_messages'),
        type: 'json',
        data: {}
    }).done(function (res) {

        if(typeof res.credits !== "undefined") {
            //$(document).find('.creditsCount').text(res.credits);

            if(res.credits < 1 && res.vip != true) {
                $('#chatMessageForm')
                    .find('textarea').prop('disabled', 'disabled')
                    .prop('placeholder', 'Désolé, votre période d\'essai est maintenant terminée. Pour recevoir les réponses de notre voyant, profitez de notre offre spéciale !');
            }
            else {
                $('#chatMessageForm')
                    .find('textarea').prop('disabled', false)
                    .prop('placeholder', '');
            }

            /*if(res.vip == true) {
                $('.coinStatus').html('VIP');
                $('.coinStatus').removeAttr('data-toggle');
            }*/
        }

        if(res.parts.length !== 0) {
            $.each(res.parts, function(k, v) {
                $('#'+k).html(v);
            });
        }


        if(res.messages) {

            let messages = res.messages,
                defaultTimer = 500;

            for (let i = 0, p = Promise.resolve(); i < messages.length; i++) {

                let timer = (i === 0) ? 400 : defaultTimer;

                p = p.then(_ => new Promise((resolve, reject)  =>
                    setTimeout(function () {

                        let message = messages[i];

                        switch (message.type) {

                            case TYPE_MESSAGE:

                                toggleTyping('show');

                                pushMessage({'content' : message.content}, TYPE_MESSAGE, 'from');
                                updateScroll();

                                resolve();

                                break;

                            case TYPE_MODULE:

                                setTimeout(function() {

                                    console.log(message.module_type);

                                    switch(message.module_type) {

                                        case 0:

                                            let $el = $(`<div class="module" data-consumed="false" />`);
                                            $el.html(message.content);

                                            $chatMessageForm.find('#live').hide();
                                            $chatMessageForm.append($el);
                                            break;

                                        case 1:
                                            pushMessage({'content' : message.content}, TYPE_MODULE, 'from');
                                            break;

                                        case 2:

                                            $('#mainModal').find('.modal-content').html(message.content);

                                            $('#mainModal').modal({
                                                show: true
                                            });
                                            break;
                                    }

                                    return;

                                }, 200);

                                break;

                        }

                    }, timer)

                ));
            }

        }

    });

}, 10000);

$('textarea[name="message"]').keypress(function (e) {

    if (e.keyCode == 13) {
        if (! e.shiftKey) {
            e.preventDefault();
            $(this).closest('form').submit();
        }
    }
});