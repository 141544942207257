window.step = 0;
window.runningScene = null;
window.scrolled = false;

const TYPE_MESSAGE = 0;
const TYPE_MODULE = 1;

let loaderHtml = $(document).find('.tplPart#spinner').html(),
    $chatMessageForm = $('#chatMessageForm'),
    $chatMessages = $('#chatMessages');

window.errorModal = function() {
    $('#errorModal').modal('show');
};

window.delay = function (t, v) {
    return new Promise(function(resolve) {
        setTimeout(resolve.bind(null, v), t)
    });
};

window.loadPart = function(url, data) {
    $.ajax({
        type: 'post',
        url: url,
        dataType: 'json',
        data: data
    }).done(function(res) {

        if(res.tplData) {
            $.each(res.tplData, function(k, v) {
                $(`#${k}`).html(v);
            });
        }

        setTimeout(function() {
            updateScroll();
        }, 200);

    });
};

window.toggleTyping = function(action) {

    if(action === 'show') {

        let content = $('#tplTyping').html(),
            $el = $(content);

        $chatMessages.append($el);
        $chatMessages.find('.typing').fadeIn(100);

        updateScroll();

    }
    else {
        $chatMessages.find('.typing').fadeOut(100).remove();
    }

};

window.getTypingSpeed = function(length) {
    return (length / 200) * 100000;
};

window.pushMessage = function(data, type, direction, raw) {

    if(type === TYPE_MESSAGE) {

        if(direction === 'from') {
            toggleTyping('hide');
        }

        var date = new Date(),
            time = ('0' + date.getHours()).slice(-2) + 'h' + ('0' + date.getMinutes()).slice(-2),
            content = $('#tplMessage_' + direction).html(),
            $el = $(content);

        if(typeof raw !== "undefined" && raw !== false) {
            $el
                .find('p').html(data.content).end()
                .find('.messageTime').html(time);
        }
        else {
            $el
                .find('.message-content').html(data.content).end()
                .find('.messageTime').html(time);
        }

        $chatMessages.append($el);
        $el.fadeIn(150);

        updateScroll();

    }

    else if(type === TYPE_MODULE) {

        var $el = $(`<div class="module" data-consumed="false" />`);
        $el.html(data.content);

        $chatMessages.append($el);
        $el.fadeIn(150);

        updateScroll();

    }

};

window.updateScroll = function(force = false){
    if(!window.scrolled || force === true) {
        let element = document.getElementById("chatContainer");
        if(null != element) {
            element.scrollTop = element.scrollHeight;
        }
    }
};

window.resetChatForm = function() {
    $chatMessageForm.html($('#messageForm').html());
};

$('#chatContainer').bind('mousewheel DOMMouseScroll', function (e) {

    let delta = (e.originalEvent.wheelDelta || -e.originalEvent.detail);
    if (delta < 0) {
        if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
            window.scrolled = false;
        }
    } else if (delta > 0) {
        window.scrolled = true;
    }
});